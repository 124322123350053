<!--
 * @Author: lbh
 * @Date: 2023-11-07 18:10:57
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-29 14:40:17
 * @Description: file content
-->
<template>
  <div class="edit-pricing-module-box">
    <div class="cell-b">
      <h4>背景設置</h4>
      <selfCell title="背景圖">
        <selfUpload
          v-model="configs.bg"
          type="default"
          @change="setValue('bg')"
        />
      </selfCell>
      <selfCell title="背景顏色">
        <el-color-picker
          v-model="configs.bgColor"
          show-alpha
          @change="setValue('bgColor')"
        ></el-color-picker>
      </selfCell>
    </div>
    <div class="cell-b">
      <h4>主標題</h4>
      <selfCell
        v-for="item in language"
        :key="item.code"
        :title="item.name"
      >
        <el-input
          v-model="configs[`${item.code == 'HK' ? '' : item.code}title`]"
          :placeholder="`請輸入標題-${item.name}`"
          @input="setValue(`${item.code == 'HK' ? '' : item.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </selfCell>
    </div>

    <div class="cell-b">
      <h4>副標題</h4>
      <selfCell
        v-for="item in language"
        :key="item.code"
        :title="item.name"
      >
        <el-input
          v-model="configs[`${item.code == 'HK' ? '' : item.code}desc`]"
          :placeholder="`請輸入副標題-${item.name}`"
          @input="setValue(`${item.code == 'HK' ? '' : item.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </selfCell>
    </div>

    <div class="cell-b">
      <div class="h4">

        <div class="label">子項板塊</div>
        <div class="value">
          <el-button-group>
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              :disabled="configs.items.length == 8"
              @click="addDom"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="configs.items.length == 1"
              @click="delDom"
            ></el-button>
          </el-button-group>
        </div>
      </div>
      <div class="cell-b tabs-box">
        <div
          v-for="item,index in configs.items"
          :key="index"
          class="tabs-box_tab"
          :class="{active:tabsActive == index}"
          @click="tabsActive = index"
        >
          {{item.title || `第${index + 1}個`}}
        </div>
      </div>

      <div class="cell-b">
        <selfCell title="背景圖">
          <selfUpload
            v-model="configs.items[tabsActive].headImg"
            type="default"
            @change="setValue('items')"
          />
        </selfCell>
        <selfCell title="主題色">
          <el-color-picker
            v-model="configs.items[tabsActive].themeColor"
            show-alpha
            @change="setValue('items')"
          ></el-color-picker>
        </selfCell>
        <div class="cell-b">
          <selfCell title="標題">
            <div>
              <el-input
                v-for="item in language"
                :key="item.code"
                v-model="configs.items[tabsActive][`${item.code == 'HK' ? '' : item.code}title`]"
                :placeholder="`請輸入標題-${item.name}`"
                @input="setValue(`items`)"
              ></el-input>
            </div>
          </selfCell>
          <selfCell title="描述">
            <div>
              <el-input
                v-for="item in language"
                :key="item.code"
                v-model="configs.items[tabsActive][`${item.code == 'HK' ? '' : item.code}desc`]"
                :placeholder="`請輸入描述-${item.name}`"
                @input="setValue(`items`)"
              ></el-input>
            </div>
          </selfCell>
          <selfCell title="標籤">
            <div>
              <selfCell title="是否顯示">
                <el-switch
                  v-model="configs.items[tabsActive].tag.show"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="setValue(`items`)"
                >
                </el-switch>
              </selfCell>
              <div>
                <el-input
                  v-for="item in language"
                  :key="item.code"
                  v-model="configs.items[tabsActive].tag[`${item.code == 'HK' ? '' : item.code}text`]"
                  :placeholder="`請輸入標籤文字-${item.name}`"
                  @input="setValue(`items`)"
                ></el-input>
              </div>
              <selfCell title="背景色">
                <el-color-picker
                  v-model="configs.items[tabsActive].tag.bg"
                  show-alpha
                  @change="setValue(`items`)"
                ></el-color-picker>
              </selfCell>
            </div>
          </selfCell>
        </div>

        <div class="cell-b">
          <selfCell title="報價">
            <div>
              <el-input
                v-for="item in language"
                :key="item.code"
                v-model="configs.items[tabsActive][`${item.code == 'HK' ? '' : item.code}price`]"
                :placeholder="`請輸入報價-${item.name}`"
                @input="setValue(`items`)"
              ></el-input>
            </div>
          </selfCell>
          <selfCell title="週期">
            <div>
              <el-input
                v-for="item in language"
                :key="item.code"
                v-model="configs.items[tabsActive][`${item.code == 'HK' ? '' : item.code}priceDesc`]"
                :placeholder="`請輸入週期-${item.name}`"
                @input="setValue(`items`)"
              ></el-input>
            </div>
          </selfCell>
          <selfCell title="描述">
            <div>
              <el-input
                v-for="item in language"
                :key="item.code"
                v-model="configs.items[tabsActive][`${item.code == 'HK' ? '' : item.code}disDesc`]"
                :placeholder="`請輸入描述-${item.name}`"
                @input="setValue(`items`)"
              ></el-input>
            </div>
          </selfCell>
        </div>
        <!-- 按鈕 -->
        <div class="cell-b">
          <selfCell title="按鈕類型">
            <el-select
              v-model="configs.items[tabsActive].btn.type"
              placeholder="請選擇按鈕類型"
              @change="setValue(`items`)"
            >
              <el-option
                label="默認"
                value="default"
              />
              <el-option
                label="線性"
                value="plain"
              />
            </el-select>
          </selfCell>
          <selfCell title="按鈕文字">
            <div>
              <el-input
                v-for="item in language"
                :key="item.code"
                v-model="configs.items[tabsActive].btn[`${item.code == 'HK' ? '' : item.code}text`]"
                :placeholder="`請輸入-${item.name}`"
                @input="setValue(`items`)"
              ></el-input>
            </div>
          </selfCell>
          <selfCell title="跳轉方式">
            <selfPagePicker
              v-model="configs.items[tabsActive].btn.go"
              @onChange="setValue(`items`)"
            />
          </selfCell>
        </div>

        <!-- 子項 -->
        <div class="cell-b">
          <h4 style="top:86px;">子項內容</h4>
          <div
            v-for="cItem,cIndex in configs.items[tabsActive].child"
            :key="cIndex"
            class="cell-b"
          >
            <el-button-group class="btn-group">
              <el-button
                type="primary"
                icon="el-icon-upload2"
                :disabled="cIndex == 0"
                size="mini"
                @click="itemCheck(cIndex,cIndex-1)"
              ></el-button>
              <el-button
                type="success"
                icon="el-icon-download"
                :disabled="cIndex == configs.items[tabsActive].child.length-1"
                size="mini"
                @click="itemCheck(cIndex,cIndex+1)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="configs.items[tabsActive].child.length == 1"
                @click="itemDel(cIndex)"
              ></el-button>
              <el-button
                type="warning"
                icon="el-icon-plus"
                size="mini"
                @click="itemAdd(cIndex)"
              ></el-button>
            </el-button-group>
            <selfCell title="類型">
              <el-select
                v-model="cItem.isTitle"
                placeholder="請選擇按鈕類型"
                @change="setValue(`items`)"
              >
                <el-option
                  label="文字"
                  :value="true"
                />
                <el-option
                  label="圖標"
                  :value="false"
                />
              </el-select>
            </selfCell>
            <selfCell
              v-if="cItem.isTitle"
              title="右側文本"
            >
              <div>
                <el-input
                  v-for="item in language"
                  :key="item.code"
                  v-model="cItem[`${item.code == 'HK' ? '' : item.code}value`]"
                  :placeholder="`請輸入-${item.name}`"
                  @input="setValue(`items`)"
                ></el-input>
              </div>
            </selfCell>
            <selfCell
              v-else
              title="右側圖標"
            >
              <selfUpload
                v-model="cItem.icon"
                type="default"
                @change="setValue('items')"
              />
            </selfCell>
            <selfCell title="文本前綴">
              <el-input
                v-model="cItem.labelH"
                placeholder="請輸入文本前綴"
                @input="setValue(`items`)"
              ></el-input>
            </selfCell>
            <selfCell title="左側文本">
              <div>
                <el-input
                  v-for="item in language"
                  :key="item.code"
                  v-model="cItem[`${item.code == 'HK' ? '' : item.code}label`]"
                  :placeholder="`請輸入-${item.name}`"
                  @input="setValue(`items`)"
                  type="textarea"
                  rows="2"
                ></el-input>
              </div>
            </selfCell>
            <selfCell title="顯示刪除線">
              <el-switch
                v-model="cItem.delete"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="setValue(`items`)"
              >
              </el-switch>
            </selfCell>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit-pricing-module",
  props: {
    configs: {
      default () {
        return {}
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      language: [],
      tabsActive: 0
    }
  },
  created () {
    this.language = this.$storage.get('language')
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    addDom (ind) {
      this.configs.items.splice(this.tabsActive, 0, this.$util.object.clone(this.configs.items[0]))
      this.setValue('items')
    },
    delDom (ind) {
      this.configs.items.splice(this.tabsActive, 1)
      if (this.tabsActive > this.configs.items.length - 1) this.tabsActive -= 1;
      this.setValue('items')
    },

    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items[this.tabsActive].child;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.setValue('items')
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items[this.tabsActive].child;
      items.splice(index, 1);
      this.setValue('items')
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items[this.tabsActive].child;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.setValue('items')
    }
  }
}
</script>

<style lang="less" scoped>
.edit-pricing-module-box {
  .tabs-box {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    position: sticky;
    top: 30px;
    z-index: 10;
    background-color: #fff;
    &::-webkit-scrollbar {
      height: 6px;
    }

    /* 滚动槽 */

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    /* 滚动条滑块 */

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--themeColor);
      -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: var(--themeColor);
    }
    .tabs-box_tab {
      font-size: 16px;
      padding: 4px 14px;
      border: 0.5px solid #ddd;
      border-left: none;
      white-space: nowrap;
      &.active {
        background: var(--themeColor);
        color: #fff;
        border-color: var(--themeColor);
      }
      &:first-child {
        border: 0.5px solid #ddd;
      }
    }
  }
}
</style>